<template>
  <v-row justify="end">
    <v-dialog v-model="isVisibleProductEdit">
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("gap_reason") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("gap") }}
                          </th>
                          <th class="text-left">
                            {{ $t("reason") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            inventoryAdjst, i
                          ) in product.inventory_adjustment"
                          :key="i"
                        >
                          <td>{{ i == 0 ? product.name : "" }}</td>
                          <template v-if="!inventoryAdjst.can_update_qte">
                            <td>
                              {{
                                inventoryAdjst.adjustment_quantity -
                                product.theoretical_qte
                              }}
                            </td>
                            <td>
                              <v-combobox
                                disabled
                                dense
                                outlined
                                :label="$t('order_origin_*')"
                                v-model="inventoryAdjst.reason"
                                :items="gapReasons"
                                hide-details
                              ></v-combobox>
                            </td>
                          </template>
                          <template
                            v-else-if="
                              inventoryAdjst.can_update_qte &&
                              inventoryAdjst.can_add_reason !== undefined &&
                              inventoryAdjst.can_add_reason !== false
                            "
                          >
                            <td>
                              {{
                                inventoryAdjst.adjustment_quantity -
                                product.theoretical_qte
                              }}
                            </td>
                            <td>
                              <v-combobox
                                dense
                                outlined
                                :label="$t('order_origin_*')"
                                v-model="product.reason"
                                :items="gapReasons"
                                :rules="[rules.required]"
                                hide-details
                              ></v-combobox>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>

            <v-btn
              color="primary"
              text
              @click="updateGapReason(product)"
              :disabled="!is_valid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";

import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      product: "inventories/product",
      isVisibleProductEdit: "inventories/isVisibleProductEdit",
      isVisibleProductShow: "inventories/isVisibleProductShow",
      hubs: "hubs/activeHubs",
    }),
  },

  data() {
    return {
      is_valid: true,
      gapReasons: ["Stock en plus", "Ecart d'inventaire négatif"],
    };
  },

  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.$store.dispatch("inventories/closeProductForm");
    },

    async updateGapReason(product) {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("inventories/updateProduct", product);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response.data.message);
        }
      }
    },
  },
};
</script>
